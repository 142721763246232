import React, { useMemo } from 'react'
import './styles.scss'
import { ArrowIcon } from '../../../shared/icons/ArrowIcon'
import useWindowSize from '../../../hooks/useWindowSize'
import { useNavigate } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'

const MainFooter = () => {
	const navigate = useNavigate()
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])
	return (
		<>
			<footer className="page-footer">
				<div className="footer-top contact-info">
					<p className="contact-title">
						Let’s connect<a className="anchor" id="contact"></a>
					</p>
					{isMobile ? (
						<>
							<p className="contact-text">
								Feel free to reach out if you want to work & collaborate with
								us, or simply have a chat.
							</p>
							<a
								className="hero-button"
								style={{ marginBottom: '60px' }}
								href="mailto:info@holywater.tech"
							>
								ConTact US <ArrowIcon ml="15px" w="24px" h="24px" />
							</a>
						</>
					) : (
						<Flex justifyContent="space-between" alignItems="center">
							<p className="contact-text contact-text-desc">
								Feel free to reach out if you want to work & collaborate with
								us, or simply have a chat.
							</p>
							<a
								href="mailto:info@holywater.tech"
								className="hero-button hero-button-desc-contact"
								style={{ marginBottom: '60px' }}
							>
								ConTact
								<br /> US <ArrowIcon ml="-25px" mt="40px" w="24px" h="24px" />
							</a>
						</Flex>
					)}
					{/* <p className="contact-text">
						Feel free to reach out if you want to work & collaborate with us, or
						simply have a chat.
					</p>
					<button className="hero-button" style={{ marginBottom: '60px' }}>
						ConTact US <ArrowIcon ml="15px" w="24px" h="24px" />
					</button> */}
					<div className="footer-bottom">
						<button
							className="sitemap"
							name="sitemap"
							onClick={() => navigate('/sitemap')}
						>
							Sitemap
						</button>
						<div className="copywriting-text">
							<p>With love from Ukraine</p>
							<span>© {new Date().getFullYear()}</span>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export { MainFooter }
