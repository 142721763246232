import React, { useMemo } from 'react'
import './styles.scss'
import { StarIcon } from '../../../shared/icons/StarIcon'
import { ArrowIcon } from '../../../shared/icons/ArrowIcon'
import useWindowSize from '../../../hooks/useWindowSize'
import { Box, Flex } from '@chakra-ui/react'

const TEXT = [
	'Curiosity and a strong drive for self-awareness',
	'Boldness and a willingness to challenge the status quo',
	'Persistence and a result-oriented mindset',
	'A focus on growth and development',
]
const SectionWork = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])
	return (
		<section className="section section-works">
			<p className="title" style={{ marginBottom: '52px' }}>
				Work with us<a className="anchor" id="work"></a>
			</p>
			<div className="wrapper-work">
				<div className="wrapper-think-list">
					<p
						className="second-text text-section-about"
						style={{ marginBottom: '32px', opacity: 0.9 }}
					>
						HOLYWATER&ensp;&ensp; is&ensp;&ensp;&ensp; an innovative, creative
						and challenging community
					</p>
					<p className="work-subtext">We are driven by:</p>
					<ul className="work-text-list">
						{TEXT.map(item => (
							<li key={item} className="work-text-item">
								<StarIcon />
								<p className="work-text">{item}</p>
							</li>
						))}
					</ul>
				</div>
				<div className="wrapper-think">
					{isMobile ? (
						<>
							<h3 className="work-title">
								Think like us?
								<span className="text-accent">Join us.</span>
							</h3>
							<p className="work-text-second">
								Find out more about our vacancies
							</p>
							<button
								className="hero-button"
								style={{ marginBottom: 0 }}
								onClick={() =>
									window.open(
										'https://jobs.dou.ua/companies/holy-water/vacancies/',
										'_blank',
									)
								}
							>
								Join Us <ArrowIcon ml="15px" w="24px" h="24px" />
							</button>
						</>
					) : (
						<Flex justifyContent="space-between">
							<Box>
								<h3 className="work-title">
									Think like us?
									<br />
									<span className="text-accent">Join us.</span>
								</h3>
								<p className="work-text-second">
									Find out more about our vacancies
								</p>
							</Box>
							<button
								onClick={() =>
									window.open(
										'https://jobs.dou.ua/companies/holy-water/vacancies/',
										'_blank',
									)
								}
								className="hero-button hero-button-desc"
								style={{ marginBottom: 0 }}
							>
								Join
								<br /> Us <ArrowIcon ml="15px" mt="-30px" w="24px" h="24px" />
							</button>
						</Flex>
					)}
				</div>
			</div>
		</section>
	)
}

export default SectionWork
